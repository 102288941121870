<template>
  <div>
    <v-card class="pa-8" v-if="!loading">
      <header-content
        :pageTitle="
          'Monthly Lender Report' +
            ' (' +
            convertMonth($route.params.month) +
            ' ' +
            $route.params.year +
            ')'
        "
      />

      <div class="d-flex">
        <v-text-field
          v-model="searchQuery"
          outlined
          rounded
          dense
          color="primary"
          label="Cari kata kunci"
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
      </div>

      <div class="d-flex">
        <div class="text-h6 font-weight-bold">{{ convertedPayload.name }}</div>
        <v-spacer />
        <!-- <download-excel
        class="btn btn-default"
        :data="monthlyLenders"
        type="csv"
        name="exported-monthly.xls"
      >-->
        <v-btn
          color="primary"
          @click="getExcel(monthlyLenders)"
          rounded
          class="ml-4 white--text text-none"
          >Export</v-btn
        >
        <!-- </download-excel> -->
      </div>

      <div class="d-flex py-2">
        <div>
          <div>Total sudah bayar</div>
          <div class="text-h6 font-weight-bold">
            {{ convertCurrency(monthlyLenders[2].total_sudah_bayar) }}
          </div>
        </div>

        <div class="px-4" />

        <div>
          <div>Disburshment</div>
          <div class="text-h6 font-weight-bold">
            {{ convertCurrency(monthlyLenders[1].disburshment) }}
          </div>
        </div>

        <div class="px-4" />

        <div>
          <div>Total sisa pinjaman</div>
          <div class="text-h6 font-weight-bold">
            {{ convertCurrency(monthlyLenders[0].total_sisa_pinjaman) }}
          </div>
        </div>
      </div>

      <v-tabs v-model="tab" background-color="transparent" color="#D65646" grow>
        <v-tab class="text-none">Sisa Pinjaman</v-tab>
        <v-tab class="text-none">Sudah Bayar</v-tab>
        <v-tab-item>
          <v-card flat>
            <v-data-table
              class="cursor-pointer"
              :headers="headers"
              :items="monthlyLenders[0].transaksi"
              :items-per-page="10"
              :search="searchQuery"
            ></v-data-table>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-data-table
              class="cursor-pointer"
              :headers="headersTwo"
              :items="monthlyLenders[2].transaksi"
              :items-per-page="10"
              :search="searchQuery"
            ></v-data-table>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
    <v-card class="pa-8" v-else>
      <v-skeleton-loader type="table"></v-skeleton-loader>
    </v-card>
  </div>
</template>

<script>
import store from "../../store/index.js";
import converters from "../../helpers/converters.js";
import HeaderContent from "../../components/Header/HeaderContent.vue";

export default {
  components: { HeaderContent },

  data() {
    return {
      searchQuery: "",
      tab: null,
      loading: false,
      headers: [
        { text: "No. Perjanjian", value: "no_perjanjian_lender" },
        {
          text: "Penawaran Pinjaman",
          value: "nilai_penawaran_pinjaman_formatted",
        },
        {
          text: "Penawaran Disetujui",
          value: "nilai_penawaran_disetujui_formatted",
        },
        {
          text: "Sisa Pinjaman Berjalan",
          value: "sisa_pinjaman_berjalan_formatted",
        },
        { text: "Sumber Dana", value: "sumber_dana" },
        { text: "Tanggal Jatuh Tempo", value: "tgl_jatuh_tempo_formatted" },
        {
          text: "Tanggal Pelunasan Penyelenggara",
          value: "tgl_pelunasan_penyelenggara_formatted",
        },
        {
          text: "Tanggal Penawaran Pinjaman",
          value: "tgl_penawaran_pemberian_pinjaman_formatted",
        },
        {
          text: "Tanggal Penyaluran Dana",
          value: "tgl_penyaluran_dana_formatted",
        },
      ],
      headersTwo: [
        { text: "Nama Pinjaman", value: "nama_pinjaman" },
        { text: "Nilai Angsuran", value: "nilai_angsuran_formatted" },
        {
          text: "Nilai Pembayaran",
          align: "start",
          sortable: true,
          value: "nilai_pembayaran_formatted",
        },

        {
          text: "Jangka Waktu Pinjaman",
          value: "jangka_waktu_pinjaman_formatted",
        },
        { text: "Nilai Pendanaan", value: "nilai_pendanaan_formatted" },
        { text: "Nilai Plafond", value: "nilai_plafond_formatted" },
        { text: "Ratio Pengajuan Pinjaman", value: "ratio_pengajuan_pinjaman" },
        {
          text: "Sisa Pinjaman Berjalan",
          value: "sisa_pinjaman_berjalan_formatted",
        },
        { text: "Sudah Bayar", value: "sudah_bayar_formatted" },
        { text: "Suku Bunga Pinjaman", value: "suku_bunga_pinjaman" },
        { text: "Dalam Negeri", value: "sumber_dana" },
      ],
      monthlyLenders: "",
      monthlyLenderPayload: {
        id: this.$route.params.id,
        month: this.$route.params.month,
        year: this.$route.params.year,
      },
      convertedPayload: {
        id: "",
        name: "",
        month: "",
        year: "",
      },
    };
  },

  methods: {
    async getExcel(data) {
      var XLSX = require("xlsx");
      let workBook = await XLSX.utils.book_new();
      let workSheet1;
      let workSheet2;
      let workSheet3;
      var XLSX = require("xlsx");
      console.log(data);
      let total = [
        {
          "Sisa Pinjaman": data[0].total_sisa_pinjaman,
          Disbursements: data[1].disburshment,
          "Sudah Bayar": data[2].total_sudah_bayar,
        },
      ];
      console.log(total);
      workSheet1 = await XLSX.utils.json_to_sheet(data[0].transaksi);
      workSheet2 = await XLSX.utils.json_to_sheet(data[2].transaksi);
      workSheet3 = await XLSX.utils.json_to_sheet(total);
      await XLSX.utils.book_append_sheet(workBook, workSheet1, `Sisa Pinjaman`);
      await XLSX.utils.book_append_sheet(workBook, workSheet2, `Sudah Bayar`);
      await XLSX.utils.book_append_sheet(workBook, workSheet3, `Total`);

      await XLSX.writeFile(
        workBook,
        `MonthlyLenderReport_${this.$route.params.id}_${this.$route.params.month}-${this.$route.params.year}.xlsx`
      );
    },
    async getMonthlyLender() {
      this.loading = true;
      await store
        .dispatch("office/fetchMonthlyLender", this.monthlyLenderPayload)
        .then((r) => {
          if (r) {
            this.loading = false;
          }
        });
      this.monthlyLenders = store.state.office.monthlyLender;
      this.monthlyLenders[0].transaksi.forEach((a) => {
        a.nilai_penawaran_pinjaman_formatted = converters.currency(
          a.nilai_penawaran_pinjaman
        );
        a.nilai_penawaran_disetujui_formatted = converters.currency(
          a.nilai_penawaran_disetujui
        );
        a.sisa_pinjaman_berjalan_formatted = converters.currency(
          a.sisa_pinjaman_berjalan
        );
        a.tgl_jatuh_tempo_formatted = converters.date(a.tgl_jatuh_tempo);
        a.tgl_pelunasan_penyelenggara_formatted = converters.date(
          a.tgl_pelunasan_penyelenggara
        );
        a.tgl_penawaran_pemberian_pinjaman_formatted = converters.date(
          a.tgl_penawaran_pemberian_pinjaman
        );
        a.tgl_penyaluran_dana_formatted = converters.date(
          a.tgl_penyaluran_dana
        );
      });
      this.monthlyLenders[2].transaksi.forEach((a) => {
        a.nilai_angsuran_formatted = converters.currency(a.nilai_angsuran);
        a.nilai_pembayaran_formatted = converters.currency(a.nilai_pembayaran);
        a.jangka_waktu_pinjaman_formatted = converters.days(
          a.jangka_waktu_pinjaman
        );
        a.nilai_pendanaan_formatted = converters.currency(a.nilai_pendanaan);
        a.nilai_plafond_formatted = converters.currency(a.nilai_plafond);
        a.sisa_pinjaman_berjalan_formatted = converters.currency(
          a.sisa_pinjaman_berjalan
        );
        a.sudah_bayar_formatted = converters.currency(a.sudah_bayar);
      });
    },
    async getCurrentLender(id) {
      await store.dispatch("office/fetchLender", id);
      this.convertedPayload.name =
        store.state.office.currentLender[0].nama_pengguna;
    },
    convertCurrency(val) {
      return converters.currency(val);
    },
    convertMonth(val) {
      return converters.month(val);
    },
  },

  mounted() {
    this.getMonthlyLender();
    this.getCurrentLender(this.$route.params.id);
    this.convertedPayload.id = this.$route.params.id;
    this.convertedPayload.month = converters.month(this.$route.params.month);
    this.convertedPayload.year = this.$route.params.year;
  },
};
</script>
